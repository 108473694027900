import {
  createContext, useContext, useEffect, useReducer, useState
} from "react";
import { getRequest, postRequest } from "utils/api";

const UserContext = createContext();

function isTokenPresent() {
  return "accessToken" in localStorage || "accessToken" in sessionStorage;
}

function isRefreshTokenPresent() {
  return "refreshToken" in localStorage || "refreshToken" in sessionStorage;
}

function getRefreshToken() {
  if (isRefreshTokenPresent()) {
    const refreshToken = localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken");

    return refreshToken;
  }
  return null;
}

function handleLogOut() {
  localStorage.removeItem("accessToken");
  sessionStorage.removeItem("accessToken");
}

const _InitialState = {
  profile: {},
  isLoading: false
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_PROFILE":
      return { ...state, profile: payload.profile };

    case "SET_IS_LOADING":
      return { ...state, isLoading: payload.isLoading };
    default:
      return state;
  }
}

export function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, _InitialState);
  const [hasActivePlan, setHasActivePlan] = useState(false);
  const [vendorProfile, setVendorProfile] = useState();

  const [assessmentStatus, setAssessmentStatus] = useState({
    isAssessmentFinished: false,
    isAssessmentStarted: false,
    isPersonalityFinished: true,
    isPersonalityRetaken: false,
    isPersonalityStarted: false,
    isRetestCompleted: false
  });

  function refreshAccessToken() {
    postRequest("/token/refresh", { refresh: getRefreshToken() })
        .then((resp) => {
          localStorage.setItem("accessToken", resp.data.access);
          sessionStorage.setItem("accessToken", resp.data.access);
          getProfile();
        })
        .catch((err) => {
          console.error(err);
        });
  }

  function getProfile() {
    dispatch({ type: "SET_IS_LOADING", payload: { isLoading: true } });
    getRequest("/accounts/profile")
        .then((resp) => {
          dispatch({
            type: "SET_PROFILE",
            payload: { profile: resp.data }
          });

          if (resp.data.user_type === "CANDIDATE") {
            getRequest("/assessment/status").then((res) => {
              const {
                has_active_plan,
                is_assessment_finished,
                is_assessment_started,
                is_personality_finished,
                is_personality_retaken,
                is_personality_started,
                is_retest_completed
              } = res.data;

              setHasActivePlan(has_active_plan);
              setAssessmentStatus({
                isAssessmentFinished: is_assessment_finished,
                isAssessmentStarted: is_assessment_started,
                isPersonalityFinished: is_personality_finished,
                isPersonalityRetaken: is_personality_retaken,
                isPersonalityStarted: is_personality_started,
                isRetestCompleted: is_retest_completed
              });
            });
          } else if (resp.data.user_type === "VENDOR") {
            getRequest("/vendors/profile").then((res) => {
              setVendorProfile(res.data);
            });
          }
        })
        .catch((err) => {
          if (isRefreshTokenPresent()) {
            refreshAccessToken();
          } else {
            handleLogOut();
          }

          console.error(err);
        })
        .finally(() => {
          dispatch({ type: "SET_IS_LOADING", payload: { isLoading: false } });
        });
  }

  useEffect(() => {
    if (isTokenPresent()) getProfile();
  }, []);

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
        getProfile,
        hasActivePlan,
        vendorProfile,
        assessmentStatus
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
