import { InputAdornment } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postRequest } from "utils/api";
import style from "./GetStarted.module.scss";

const useStyles = makeStyles((theme) => ({
  edit: {
    backgroundColor: "white !important",
    borderRadius: "50px",
    height: "60px",
    width: "169px",
    fontSize: "16px",
    fontWeight: "500",

    // },

    subtext: {
      fontSize: "16px",
      fontWeight: "400",
      color: "white"
    }
  }
}));

export default function GetStart({ text, subText, buttonText }) {
  const navigateTo = useNavigate();

  const [emailError, setEmailError] = useState(false);
  const [formData, setFormData] = useState({ email: "" });
  // const [hover, sethover] = useState(false);

  const classes = useStyles();

  async function handleGetStarted() {
    setEmailError(false);

    const { email } = formData;

    postRequest(
        "/web/subscriptions",
        {
          email
        },
        { noAuth: true }
    )
        .then(() => {
          navigateTo(`/auth/register?email=${email}`);
        })
        .catch((e) => {
          console.error(e);
        });
  }

  function handleChangeForm(e) {
    setEmailError(false);

    const { value } = e.target;
    setFormData({ email: value });
  }

  return (
    <div className={style.root}>
      <Grid container className={style.maincontainer}>
        <Grid item xs={10} sm={12} md={12} lg={6} className={style.grid}>
          <div className={style.maintext}>
            <div className={style.text}>{text}</div>
            <div className={style.subtext}>{subText}</div>
          </div>
        </Grid>
        <Grid item xs={10} sm={12} md={12} lg={6} className={style.grid2}>
          <Formik initialValues={formData} onSubmit={handleGetStarted}>
            {({ handleSubmit }) => (
              <form onChange={handleChangeForm} onSubmit={handleSubmit} className={style.form}>
                <InputBase
                  className={style.margin}
                  type="email"
                  name="email"
                  value={formData.email}
                  placeholder="yours@gmail.com"
                  inputProps={{ "aria-label": "naked" }}
                  style={{ color: "white" }}
                  startAdornment={(
                    <InputAdornment position="start">
                      <span className={`${classes.symbol} ${style.symbol}`}>📩</span>
                    </InputAdornment>
                  )}
                />

                {/* {emailError && <p>Enter a valid email address*</p>} */}
                <button className={style.edit}>{buttonText}</button>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
}
