import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./index/App";

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

// Register service worker and clear cache if necessary
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister(); // Unregister existing service workers
      }
    });

    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName); // Clear cached files
      });
    });
  });
}
